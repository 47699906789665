import React, { useState } from "react";
import { TableIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import { Tab, TabList, TabGroup, TabPanel } from "@headlessui/react";

// Tool definition and schema
export const postgresSqlSchema = {
  postgres_sql: {
    parameters: {
      type: "object",
      required: ["query"],
      properties: {
        query: {
          type: "string",
          description: "The SQL query to execute",
        },
        row_limit: {
          type: "integer",
          description: "The number of rows to return (default is 100)",
          default: 100,
        },
      },
    },
    description: "Execute a SQL query on Postgres",
  },
};

export const toolMetadata = {
  name: "postgres_sql",
  displayName: "Postgres SQL",
  description: "Execute a SQL query on Postgres",
  requiredIntegration: "postgres",
};

const PostgresSql = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;
  const isReady = result && result.status;

  const renderTable = (data) => {
    if (!data || !data.headers || !data.rows) return null;
    const { headers, rows } = data;
  
    if (headers.length === 0 || rows.length === 0) {
      return <p className="text-gray-500 italic">{data.message || "No data to display."}</p>;
    }
  
    return (
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, cellIndex) => (
                <td key={cellIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {row[header] !== null ? row[header].toString() : "NULL"}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="border rounded-lg w-full overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <TableIcon className="w-5 h-5" />}
          <span className="font-medium">Querying Data</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          <TabGroup>
            <TabList className="flex p-1 space-x-1 bg-gray-100 dark:bg-zinc-950 rounded-xl">
              <Tab className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-t-lg focus:outline-none ${
                  selected ? 'bg-white dark:bg-zinc-900' : 'hover:bg-gray-200 dark:hover:bg-zinc-700'
                }`
              }>
                SQL
              </Tab>
              <Tab className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-t-lg focus:outline-none ${
                  selected ? 'bg-white dark:bg-zinc-900' : 'hover:bg-gray-200 dark:hover:bg-zinc-700'
                }`
              }>
                Results
              </Tab>
            </TabList>
            <div className="mt-4 min-h-[200px]">
              <TabPanel>
                <pre className="whitespace-pre-wrap bg-gray-100 dark:bg-zinc-800 rounded text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-zinc-700 p-4 h-full">{args.query}</pre>
              </TabPanel>
              <TabPanel>
                <div className="overflow-x-auto h-full">
                  {result.status === "success" ? (
                    renderTable(result.result.content)
                  ) : (
                    <div className="bg-gray-100 dark:bg-zinc-800 rounded text-red-500 dark:text-red-400 border border-gray-300 dark:border-zinc-700 p-4 h-full">
                      Error executing Postgres SQL query: {result.result.error || "Unknown error"}
                    </div>
                  )}
                </div>
              </TabPanel>
            </div>
          </TabGroup>
        </div>
      )}
    </div>
  );
};

export default PostgresSql;
