import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";

export const searchRedditSchema = {
  search_reddit: {
    parameters: {
      type: "object",
      required: ["search_term"],
      properties: {
        search_term: {
          type: "string",
          description: "The term to search for on Reddit",
        },
        time_filter: {
          type: "string",
          enum: ["all", "day", "hour", "month", "week", "year"],
          default: "all",
          description: "The time period to filter by",
        },
        limit: {
          type: "integer",
          default: 10,
          description: "Number of posts to fetch",
        },
        include_comments: {
          type: "boolean",
          default: true,
          description: "Whether to include comments in the results",
        },
      },
    },
    required: ["search_term"],
    description: "Search Reddit for posts matching a search term",
  },
};

export const toolMetadata = {
  name: "search_reddit",
  displayName: "Search Reddit",
  description: "Search Reddit for posts matching a search term",
};

const RedditIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className={className}
    viewBox="0 0 24 24"
  >
    <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z" />
  </svg>
);

const SearchReddit = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  const isReady = result && (result.status === "success" || result.status === "error");

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} 
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5 shrink-0" /> : <RedditIcon className="w-5 h-5 shrink-0" />}
          <span className="font-medium">
            Searching Reddit for "{args.search_term}"
            {args.time_filter && args.time_filter !== "all" && ` from the last ${args.time_filter}`}
            {args.limit && ` • Up to ${args.limit} results`}
          </span>
        </div>
        <div className="flex items-center">
          {isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}
        </div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.status === "success" ? (
            <div className="space-y-6">
              {result.result.content.map((post, index) => (
                <div key={index} className="border-b pb-4 last:border-b-0 last:pb-0">
                  <div className="font-medium text-lg">
                    <a href={post.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                      {post.title}
                    </a>
                  </div>
                  <div className="text-sm text-gray-600">
                    Posted in r/{post.subreddit}
                  </div>
                  {post.text && (
                    <div className="mt-2 text-sm prose prose-stone max-w-none">
                      <ReactMarkdown 
                        rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["noopener", "noreferrer"] }]]}
                        remarkPlugins={[remarkGfm]}
                      >
                        {post.text}
                      </ReactMarkdown>
                    </div>
                  )}
                  <div className="mt-2 text-sm text-gray-600">
                    Score: {post.score} | Comments: {post.num_comments}
                  </div>
                  {args.include_comments && post.comments && post.comments.length > 0 && (
                    <div className="mt-3 pl-4 border-l-2">
                      <div className="font-medium mb-2">Top Comments:</div>
                      {post.comments.slice(0, 3).map((comment, commentIndex) => (
                        <div key={commentIndex} className="mb-2 text-sm">
                          <div className="text-gray-600">u/{comment.author} • Score: {comment.score}</div>
                          <div>{comment.body}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-red-600">Error searching Reddit: {result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchReddit;