import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";

export const searchTikTokSchema = {
  search_tiktok: {
    parameters: {
      type: "object",
      required: ["search_term"],
      properties: {
        search_term: {
          type: "string",
          description: "The term to search for on TikTok",
        },
        period: {
          type: "string",
          enum: ["0", "1", "7", "30", "90", "180"],
          default: "7",
          description: "Only return posts more recent than given period in days",
        },
        limit: {
          type: "integer",
          default: 10,
          maximum: 20,
          description: "Number of posts to fetch (max 20)",
        },
        include_comments: {
          type: "boolean",
          default: true,
          description: "Whether to include comments in the results",
        },
      },
    },
    required: ["search_term"],
    description: "Search TikTok for posts matching a search term",
  },
};

export const toolMetadata = {
  name: "search_tiktok",
  displayName: "Search TikTok",
  description: "Search TikTok for posts matching a search term",
};

const TikTokIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className={className}
    viewBox="0 0 90 90"
  >
    <path 
      d="M36.203 35.438v-3.51c-1.218-0.173-2.447-0.262-3.677-0.268-15.047 0-27.289 12.244-27.289 27.291 0 9.23 4.613 17.401 11.65 22.342-4.712-5.039-7.332-11.681-7.328-18.58 0-14.833 11.894-26.929 26.644-27.275"
      fill="#00F2EA"
    />
    <path 
      d="M36.847 75.175c6.714 0 12.19-5.341 12.44-11.997l0.023-59.417h10.855c-0.232-1.241-0.349-2.5-0.35-3.762H44.989l-0.025 59.419c-0.247 6.654-5.726 11.993-12.438 11.993-2.015 0.001-4-0.49-5.782-1.431 2.335 3.256 6.095 5.189 10.103 5.193M80.441 23.93v-3.302c-3.989 0.004-7.893-1.157-11.232-3.339 2.928 3.371 6.869 5.701 11.234 6.641"
      fill="#00F2EA"
    />
    <path 
      d="M69.209 17.286c-3.272-3.744-5.075-8.549-5.073-13.522h-3.972C61.203 9.318 64.472 14.205 69.209 17.286M32.526 46.486c-6.88 0.008-12.455 5.583-12.463 12.463 0.004 4.632 2.576 8.88 6.679 11.032-1.533-2.114-2.358-4.657-2.358-7.268 0.007-6.88 5.582-12.457 12.463-12.465 1.284 0 2.515 0.212 3.677 0.577V35.689c-1.218-0.173-2.447-0.262-3.677-0.268-0.216 0-0.429 0.012-0.643 0.016v11.626C35.014 46.685 33.774 46.49 32.526 46.486"
      fill="#FF004F"
    />
    <path 
      d="M80.441 23.93v11.523c-7.689 0-14.81-2.459-20.627-6.633v30.13c0 15.047-12.24 27.289-27.287 27.289-5.815 0-11.207-1.835-15.639-4.947 5.151 5.555 12.384 8.711 19.959 8.709 15.047 0 27.289-12.242 27.289-27.287v-30.13c6.009 4.321 13.226 6.642 20.627 6.633V24.387c-1.484 0-2.927-0.161-4.323-0.46"
      fill="#FF004F"
    />
    <path 
      d="M59.813 58.949v-30.13c6.009 4.322 13.226 6.642 20.627 6.633V23.93c-4.364-0.941-8.305-3.272-11.232-6.644-4.737-3.081-8.006-7.968-9.045-13.522H49.309l-0.023 59.417c-0.249 6.654-5.726 11.995-12.44 11.995-4.007-0.004-7.768-1.938-10.102-5.194-4.103-2.151-6.676-6.399-6.681-11.032 0.008-6.88 5.583-12.455 12.463-12.463 1.282 0 2.513 0.21 3.677 0.577V35.438C21.453 35.784 9.559 47.88 9.559 62.713c0 7.173 2.787 13.703 7.328 18.58 4.578 3.223 10.041 4.95 15.639 4.945C47.574 86.238 59.813 73.996 59.813 58.949"
      fill="#000000"
    />
  </svg>
);

const SearchTikTok = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;
  const content = result?.result?.content || result?.content || [];
  const status = result?.result?.status || result?.status;

  const isReady = result && (status === "success" || status === "error");
  
  const formatPeriod = (period) => {
    switch(period) {
      case "0": return "today";
      case "1": return "last 24 hours";
      case "7": return "last week";
      case "30": return "last month";
      case "90": return "last 3 months";
      case "180": return "last 6 months";
      default: return `last ${period} days`;
    }
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? "cursor-pointer" : ""}`} 
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5 shrink-0" /> : <TikTokIcon className="w-5 h-5 shrink-0" />}
          <span className="font-medium">
            Searching TikTok for "{args.search_term}"
            {args.period && ` from ${formatPeriod(args.period)}`}
            {args.limit && ` • Up to ${args.limit} results`}
          </span>
        </div>
        <div className="flex items-center">
          {isReady && (isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}
        </div>
      </div>

      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {status === "success" ? (
            <div className="space-y-6">
              {content.map((post, index) => (
                <div key={index} className="border-b pb-4 last:border-b-0 last:pb-0">
                  <div className="flex items-start justify-between">
                    <div className="space-y-2">
                      <div className="font-medium text-lg">
                        <a href={`https://www.tiktok.com/@${post.author.unique_id}/video/${post.id}`} 
                           target="_blank" 
                           rel="noopener noreferrer" 
                           className="text-blue-600 hover:underline">
                          {post.desc || "No description"}
                        </a>
                      </div>
                      <div className="text-sm text-gray-600">
                        Posted by @{post.author.unique_id} • {post.statistics.play_count.toLocaleString()} views
                        • {post.statistics.digg_count.toLocaleString()} likes
                        • {post.statistics.comment_count.toLocaleString()} comments
                      </div>
                    </div>
                  </div>
                  
                  {post.comments && post.comments.length > 0 && (
                    <div className="mt-4 space-y-3">
                      <div className="font-medium">Top Comments:</div>
                      {post.comments.map((comment, idx) => (
                        <div key={idx} className="text-sm bg-gray-50 p-3 rounded">
                          <div className="font-medium">@{comment.author.unique_id}</div>
                          <div>{comment.text}</div>
                          <div className="text-gray-500 text-xs mt-1">
                            {comment.digg_count.toLocaleString()} likes
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-red-500">{result?.message || "An error occurred"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchTikTok;