import React, { useState } from "react";
import { TableIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";
import { Tab, TabList, TabGroup, TabPanel } from "@headlessui/react";
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';

// Tool definition and schema
export const executePythonSchema = {
  execute_python: {
    parameters: {
      type: "object",
      required: ["code"],
      properties: {
        code: {
          type: "string",
          description: "The python code to execute in a single cell",
        },
      },
    },
    description: "Execute python code in a Jupyter notebook cell and returns any result, stdout, stderr, display_data, and error.",
  },
};

export const toolMetadata = {
  name: "execute_python",
  displayName: "Execute Python in Code Interpreter",
  description: "Execute python code in a Jupyter notebook cell",
};

const ExecutePython = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation || !invocation.args) {
    return null;
  }

  const { result, args } = invocation;
  const isReady = result && result.status;

  const renderContent = (content) => {
    // Skip null or undefined values
    const contentTypes = Object.keys(content).filter(key => content[key] != null);
    
    return contentTypes.map((type, index) => {
      const value = content[type];
      switch (type) {
        case 'text':
          return <pre key={index} className="whitespace-pre-wrap">{value}</pre>;
        case 'png':
          return <img key={index} src={`data:image/png;base64,${value}`} alt="PNG result" />;
        case 'jpeg':
          return <img key={index} src={`data:image/jpeg;base64,${value}`} alt="JPEG result" />;
        case 'html':
          return <div key={index} dangerouslySetInnerHTML={{ __html: value }} />;
        case 'markdown':
          return <ReactMarkdown key={index} rehypePlugins={[[rehypeExternalLinks, { target: "_blank", rel: ["noopener", "noreferrer"] }]]} remarkPlugins={[remarkGfm]}>
                    {value}
                 </ReactMarkdown>;
        case 'svg':
          return <div key={index} dangerouslySetInnerHTML={{ __html: value }} />;
        case 'pdf':
          return <embed key={index} src={`data:application/pdf;base64,${value}`} type="application/pdf" width="100%" height="600px" />;
        case 'latex':
          return <pre key={index} className="whitespace-pre-wrap">{value}</pre>;
        case 'json':
          return <pre key={index} className="whitespace-pre-wrap">{JSON.stringify(value, null, 2)}</pre>;
        case 'javascript':
          return <pre key={index} className="whitespace-pre-wrap">{value}</pre>;
        case 'chart':
          // You may want to add special handling for chart data
          // return <pre key={index} className="whitespace-pre-wrap">{JSON.stringify(value, null, 2)}</pre>;
        default:
          console.warn(`Unknown content type: ${type}`);
          return null;
      }
    }).filter(Boolean); // Remove any null renders
  };

  const renderError = (error) => {
    return (
      <div className="bg-gray-100 dark:bg-zinc-800 rounded text-red-500 dark:text-red-400 border border-gray-300 dark:border-zinc-700 p-4 h-full">
        <p><strong>{error.name}:</strong> {error.value}</p>
        <pre className="whitespace-pre-wrap">{error.traceback_raw.join('\n')}</pre>
      </div>
    );
  };

  return (
    <div className="border rounded-lg w-full overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <TableIcon className="w-5 h-5" />}
          <span className="font-medium">Executing Code</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          <TabGroup>
            <TabList className="flex p-1 space-x-1 bg-gray-100 dark:bg-zinc-950 rounded-xl">
              <Tab className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-t-lg focus:outline-none ${
                  selected ? 'bg-white dark:bg-zinc-900' : 'hover:bg-gray-200 dark:hover:bg-zinc-700'
                }`
              }>
                Code
              </Tab>
              <Tab className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium text-blue-700 rounded-t-lg focus:outline-none ${
                  selected ? 'bg-white dark:bg-zinc-900' : 'hover:bg-gray-200 dark:hover:bg-zinc-700'
                }`
              }>
                Output
              </Tab>
            </TabList>
            <div className="mt-4 min-h-[200px]">
              <TabPanel>
                <pre className="whitespace-pre-wrap bg-gray-100 dark:bg-zinc-800 rounded text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-zinc-700 p-4 h-full">{args.code}</pre>
              </TabPanel>
              <TabPanel>
                <div className="overflow-x-auto h-full">
                  {result.status === "success" && result.result && result.result.content ? (
                    result.result.content.map((content, index) => (
                      <div key={index} className="mb-4">
                        {renderContent(content)}
                      </div>
                    ))
                  ) : result.status === "error" ? (
                    renderError(result.result?.message || { value: "Unknown error" })
                  ) : (
                    <div className="bg-gray-100 dark:bg-zinc-800 rounded text-red-500 dark:text-red-400 border border-gray-300 dark:border-zinc-700 p-4 h-full">
                      Error executing code: Unknown error
                    </div>
                  )}
                </div>
              </TabPanel>
            </div>
          </TabGroup>
        </div>
      )}
    </div>
  );
};

export default ExecutePython;